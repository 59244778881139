<template>
  <div style="background-color: white; margin: 10px 10px 10px 10px; padding: 14px; border-radius: 5px;">

    <h1 style="font-size: 30px">Генетические варианты</h1>

    <ATable
        :columns="columns"
        :row-key="record => record.id"
        :dataSource="dataSource"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'position'">
          <RouterLink :to="'/variants/' + record.id">
            {{ record.position }}
          </RouterLink>
        </template>
        <template v-if="column.key === 'varianceDna'">
          <DnaVariance :reference="record.ref" :alt="record.alt"/>
        </template>
        <template v-if="column.key === 'varianceProtein'">
          <ProteinVariance :variance="record.varianceProtein" v-if="record.varianceProtein != null"/>
        </template>
        <template v-if="column.key === 'clinvarAnnotation'">
          {{ clinvarAnnotations[record.clinvarAnnotation] }}
        </template>
        <template v-if="column.key === 'effect'">
          {{ effects[record.effect] }}
        </template>
        <template v-if="column.key === 'frequency'">
          <div
              :title="'' + record.somaticSamples + '/' + record.totalSomatic + ' соматических\n' + record.germlineSamples + '/' + record.totalGermline + ' герминальных\n' + record.unknownSamples + '/' + record.totalUnknown + ' неизвестных'">
            {{ record.frequency }}
          </div>
        </template>
      </template>
    </ATable>
  </div>
</template>

<style>
</style>
<script setup>
import {usePagination} from 'vue-request';
import { api, metadata } from "@/http-common";
import {ref, watch} from "vue";
import {clinvarAnnotations, effects} from "@/constants";
import DnaVariance from "@/components/DnaVariance.vue";
import ProteinVariance from "@/components/ProteinVariance.vue";
import {useRoute} from "vue-router";

const route = useRoute();

const columns = [
  {
    title: 'Ген',
    dataIndex: 'gene',
    filters: metadata.genes.map(gene => {
      return {text: gene, value: gene}
    }),
    key: 'gene',
  }, {
    title: 'Позиция',
    dataIndex: 'position',
    key: 'position',
  }, {
    title: 'Вариант в кДНК',
    dataIndex: 'varianceDna',
    key: 'varianceDna',
  }, {
    title: 'Вариант в белке',
    dataIndex: 'varianceProtein',
    key: 'varianceProtein',
  }, {
    title: 'Экзон',
    dataIndex: 'exon',
    filters: metadata.exons.map(exon => {
      return {text: exon, value: exon}
    }),
    key: 'exon',
  }, {
    title: 'Частота GnomAD3',
    dataIndex: 'frequencyGnomAd',
    key: 'frequencyGnomAd',
  }, {
    title: 'ClinVar аннотация',
    dataIndex: 'clinvarAnnotation',
    key: 'clinvarAnnotation',
    filters: metadata.clinvars.map(clinvar => {
      return {text: clinvarAnnotations[clinvar], value: clinvar}
    }),
  }, {
    title: 'Эффект Варианта',
    dataIndex: 'effect',
    filters: metadata.effects.map(effect => {
      return {text: effects[effect], value: effect}
    }),
    key: 'effect',
  }, {
    title: 'Частота в проекте',
    dataIndex: 'frequency',
    key: 'frequency',
  },
];

const queryData = (params) => {
  let filters = '';
  if (params.gene) {
    for (const gene of params.gene) {
      filters += "&gene=" + gene
    }
  }

  if (params.exon) {
    for (const exon of params.exon) {
      filters += "&exon=" + exon
    }
  }

  if (params.clinvarAnnotation) {
    for (const clinvar of params.clinvarAnnotation) {
      filters += "&clinvar=" + clinvar
    }
  }

  if (params.effect) {
    for (const effect of params.effect) {
      filters += "&effect=" + effect
    }
  }

  if (route.query.q) {
    filters += "&q=" + route.query.q
  }

  return api.get("/variants?page=" + params.current + '&pageSize=' + params.pageSize + filters).then((response) => {
    pagination.value.total = response.data.total;
    pagination.value.current = response.data.current;
    pagination.value.pageSize = response.data.pageSize;
    return response.data.data;
  });
}

const {
  data: dataSource,
  run,
  loading,
  current,
  pageSize,
  refresh
} = usePagination(queryData, {
  pagination: {
    currentKey: 'current',
    pageSizeKey: 'pageSize',
    totalKey: 'total',
    totalPageKey: 'totalPage',
  },
});

const pagination = ref({
  total: 0,
  current: current.value,
  pageSize: pageSize.value,
});

const handleTableChange = (
    page,
    filters,
    sorter,
) => {
  run({
    pageSize: page.pageSize,
    current: page?.current,
    sortField: sorter.field,
    sortOrder: sorter.order,
    ...filters,
  });
};

watch(
    () => route.query,
    async () => {
      current.value = 1;
      refresh();
    }
);
</script>
