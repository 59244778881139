<template>
  <ASpace wrap style="margin-top: 10px; margin-bottom: 0">
    <AButton v-on:click="backHandler" style="margin-left: 10px; padding-left: 10px; padding-right: 10px">
      <ArrowLeftOutlined/>
    </AButton>
    {{ variant.position}}
    <strong>{{ mainTranscriptHGVSc }}</strong>
    Ген: {{ variant.annotation.geneSymbol }}
    Тип варианта: {{ effects[variant.effect] }}
    <span v-if="variant.annotation.exon">
      Экзон: {{ variant.annotation.exon }}
    </span>
  </ASpace>

  <ASpace wrap style="float: right; margin-right: 10px; margin-top: 10px">
    <AButton type="default" v-if="variant.annotation.clinvarId" style="padding-left: 10px; padding-right: 10px">
      <a target="_blank" :href="'https://preview.ncbi.nlm.nih.gov/clinvar/variation/' + variant.annotation.clinvarId">ClinVar</a>
    </AButton>

    <AButton type="default" style="padding-left: 10px; padding-right: 10px">
      <a target="_blank" :href="'https://varsome.com/position/hg38/' + variant.position" >VarSome</a>
    </AButton>

    <AButton type="primary" style="padding-left: 10px; padding-right: 10px">
      <RouterLink :to="'/samples/' + variant.id">Связанные образцы</RouterLink>
    </AButton>
  </ASpace>
  <!--  </div>-->
  <!--  <div style="clear: both"></div>-->
  <ARow>
    <ACol :span="12">
      <ACard title="Транскрипты">
        <ATable
            :dataSource="variant.annotation.transcripts"
            :columns="transcriptsColumns"
            :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'consequence'">
              {{ effects[record.consequence] }}
            </template>
            <template v-if="column.key === 'id'">
              <span :style="record.main ? 'font-weight: bold' : ''">{{ record.id }}</span>
            </template>
            <template v-if="column.key === 'hgvsp'">
              <ProteinVariance :variance="record.hgvsp"/>
            </template>
            <template v-if="column.key === 'hgvsc'">
              <HgvscVariance :variance="record.hgvsc"/>
            </template>
          </template>
        </ATable>

      </ACard>
    </ACol>
    <ACol :span="12">
      <ACard title="ClinVar">
        <ATable
            v-if="variant.annotation.clinvarPheotypes.length > 0"
            :dataSource="variant.annotation.clinvarPheotypes"
            :columns="clinvarColumns"
            :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'phenotype'">
              {{ record.phenotype.replace("_", " ") }}
            </template>
            <template v-if="column.key === 'tumorType'">
              {{ clinvarAnnotations[record.tumorType] }}
            </template>
          </template>
        </ATable>
        <AEmpty v-if="variant.annotation.clinvarPheotypes.length <= 0" :image="Empty.PRESENTED_IMAGE_SIMPLE"/>
      </ACard>
    </ACol>
  </ARow>
  <ARow style="margin-top: 14px;">
    <ACol :span="24">
      <ACard title="GnomAD3">
        <AFlex gap="large" wrap="wrap" v-if="variant.annotation.gnomad3AlleleFrequency.length > 0">
          <div v-for="item in variant.annotation.gnomad3AlleleFrequency" :key="item.name" style="white-space: nowrap">
            <div style="width: 300px; text-align: right">
              {{ item.name }} - {{ item.frequency }}
            </div>
          </div>
        </AFlex>
        <AEmpty v-if="variant.annotation.gnomad3AlleleFrequency.length <= 0" :image="Empty.PRESENTED_IMAGE_SIMPLE"/>
      </ACard>
    </ACol>
  </ARow>
  <ARow style="margin-top: 14px;">
    <ACol :span="24">
      <ACard title="ExAC">
        <AFlex gap="large" wrap="wrap" v-if="variant.annotation.exacAlleleFrequency.length > 0">
          <div v-for="item in variant.annotation.exacAlleleFrequency" :key="item.name" style="white-space: nowrap">
            <div style="width: 300px; text-align: right">
              {{ item.name }} - {{ item.frequency }}
            </div>
          </div>
        </AFlex>
        <AEmpty v-if="variant.annotation.exacAlleleFrequency.length <= 0" :image="Empty.PRESENTED_IMAGE_SIMPLE"/>
      </ACard>
    </ACol>
  </ARow>
</template>
<style>
.ant-card {
  margin: 5px;
}

.ant-row {
  padding: 5px;
}

.ant-card-head-title {
  padding: 15px;
}
</style>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {ArrowLeftOutlined} from '@ant-design/icons-vue';
import {api} from "@/http-common";
import {clinvarAnnotations, effects} from "@/constants";
import ProteinVariance from "@/components/ProteinVariance.vue";
import HgvscVariance from "@/components/HgvscVariance.vue";
import {Empty} from "ant-design-vue";

const route = useRoute();
const router = useRouter();

const variant = await api.get("/variants/" + route.params.id).then(res => res.data)

const transcriptsColumns = [
  {
    title: 'Транскрипт',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: 'Влияние',
    dataIndex: 'consequence',
    key: 'consequence',
  }, {
    title: 'HGVSc',
    dataIndex: 'hgvsc',
    key: 'hgvsc',
  }, {
    title: 'HGVSp',
    dataIndex: 'hgvsp',
    key: 'hgvsp',
  }
]

const clinvarColumns = [
  {
    title: 'Фенотип',
    dataIndex: 'phenotype',
    key: 'phenotype',
  }, {
    title: 'Клиническая значимость',
    dataIndex: 'tumorType',
    key: 'tumorType',
  }, {
    title: 'Статус проверки',
    dataIndex: 'metadata',
    key: 'metadata',
  }
]

const backHandler = () => router.push("/variants/")

let mainTranscriptHGVSc = '';

for (let i = 0; i < variant.annotation.transcripts.length; i++) {
  let transcript = variant.annotation.transcripts[i];
  if (transcript.main) {
    mainTranscriptHGVSc = transcript.hgvsc;
  }
}

</script>