<template>
  <div :title="tooltip" style="white-space: nowrap">
    {{ label }}
    <CopyOutlined v-if="trimmed" @click="clicked"/>
  </div>

</template>
<script setup>
import {toRefs} from "vue";
import {CopyOutlined} from '@ant-design/icons-vue';

const props = defineProps(['variance'])

const {variance} = toRefs(props);

let proteinVar = variance.value ? variance.value : ''

const maxSymbols = 8;

let trimmed = false;
let label = "";
if (proteinVar.length <= maxSymbols) {
  label += proteinVar;
} else {
  trimmed = true;
  label += proteinVar.substr(0, maxSymbols) + '…';
}

const tooltip = trimmed ? proteinVar : '';

const clicked = async () => navigator.clipboard.writeText(proteinVar)

</script>