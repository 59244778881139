<template>
  <ARow style="background-color: white;">
    <ACol :span="16">
      <AMenu mode="horizontal" style="padding-bottom: 12px" :selected-keys="selectedKeys">
        <AMenuItem key="about" style="font-weight: bold;">
          <img src="/assets/logo.svg"/>
          <RouterLink to="/" style="margin-left: 10px;">OncoLung</RouterLink>
        </AMenuItem>
        <AMenuItem key="variants">
          <RouterLink to="/variants">Генетические варианты</RouterLink>
        </AMenuItem>
        <AMenuItem key="samples">
          <RouterLink to="/samples">Образцы</RouterLink>
        </AMenuItem>
        <AMenuItem key="contacts">
          <RouterLink to="/contacts">Контакты</RouterLink>
        </AMenuItem>
      </AMenu>
    </ACol>
    <ACol :span="8">
      <AInputSearch
          v-model:value="query"
          placeholder="Например: “c.2156G>C”, “EGFR” или “p.Gly12Asp”"
          size="large"
          enter-button="Поиск"
          @search="onSearch"
          style="width: 100%; padding-top: 10px"
      />
    </ACol>
  </ARow>

</template>

<style>
.ant-menu {
  font-size: 16px;
}

.ant-input {
  height: 40px;
}
</style>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {ref, watch} from "vue";

const route = useRoute();
const router = useRouter();

const replacements = {
  'variantDetails': 'variants',
  'variants': 'variants',
  'about': 'about',
  'contacts': 'contacts',
  'samples': 'samples',
  'samplesByVariant': 'samples',
}

const selectedKeys = ref([replacements[route.name]]);
const query = ref(route.query.q)

watch(
    () => route.name,
    async () => {
      selectedKeys.value = [replacements[route.name]]
      query.value = route.query.q
      return true;
    }
);

const onSearch = (query) => {
  router.push({ path: "/variants", query: {q: query}})
  return true
}

</script>