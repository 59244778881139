import {createApp} from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import {createWebHistory, createRouter} from "vue-router";

import GeneVariants from "@/components/GeneVariants.vue";
import VariantSamples from "@/components/VariantSamples.vue";
import AboutPage from "@/components/AboutPage.vue";
import ContactsPage from "@/components/ContactsPage.vue";
import VariantDetails from "@/components/VariantDetails.vue";

const routes = [{
    path: '/',
    component: AboutPage,
    name: 'about',
}, {
    path: '/variants',
    component: GeneVariants,
    name: 'variants',
}, {
    path: '/variants/:id',
    component: VariantDetails,
    name: 'variantDetails',
}, {
    path: '/samples',
    component: VariantSamples,
    name: 'samples',
}, {
    path: '/samples/:id',
    component: VariantSamples,
    name: 'samplesByVariant',
}, {
    path: '/contacts',
    component: ContactsPage,
    name: 'contacts',
}]

const router = createRouter({
    mode: 'history',
    routes: routes,
    history: createWebHistory()
});

const app = createApp(App);
app.use(Antd);
app.use(router);

router.isReady().then(() => app.mount('#app'))
