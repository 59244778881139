<template>
  <div :title="tooltip" style="white-space: nowrap">
    {{ label }}
    <CopyOutlined v-if="trimmed" @click="clicked"/>
  </div>

</template>
<script setup>
import {toRefs} from "vue";
import {CopyOutlined} from '@ant-design/icons-vue';

const substitutions = {
  "Gly": "G",
  "Leu": "L",
  "Tyr": "Y",
  "Ser": "S",
  "Glu": "E",
  "Gln": "Q",
  "Asp": "D",
  "Asn": "N",
  "Phe": "F",
  "Ala": "A",
  "Lys": "K",
  "Arg": "R",
  "His": "H",
  "Cys": "C",
  "Val": "V",
  "Pro": "P",
  "Hyp": "hP",
  "Trp": "W",
  "Ile": "I",
  "Met": "M",
  "Thr": "T",
  "Hyl": "hK",
  "Ter": "X",
  "Del": "del",
}

const props = defineProps(['variance'])

const {variance} = toRefs(props);

let proteinVar = variance.value ? variance.value : ''

for (const k in substitutions) {
  proteinVar = proteinVar.replace(k, substitutions[k]);
}

const maxSymbols = 8;

let trimmed = false;
let label = "";
if (proteinVar.length <= maxSymbols) {
  label += proteinVar;
} else {
  trimmed = true;
  label += proteinVar.substr(0, maxSymbols) + '…';
}

const tooltip = trimmed ? proteinVar : '';

const clicked = async () => navigator.clipboard.writeText(proteinVar)

</script>