<template>
  <div style="background-color: white; margin: 10px 10px 10px 10px; padding: 14px; border-radius: 5px;">
    <img src="assets/background.png" style="width: 100%; border-radius: 5px;"/>

    <h1 class="title">О проекте OncoLung</h1>
    <ATypography>
      <ATypographyParagraph>
        База данных OncoLung содержит информацию о частотах вариантов в генах системы репарации ДНК (homologous
        recombination repair, HRR), полученных в рамках проекта «Наследственные синдромы в РФ». В проект были включены
        такие гены, как ТР53, PTEN, CDH1, STK11, MLH1, MSH2, MSH6, PMS2, PALB2, CHEK2, ATM, RAD51C, RAD51D, BRIP1,
        BRCA1и
        BRCA2.
      </ATypographyParagraph>
      <ATypographyParagraph>
        Из более чем 2000 образцов из 35 регионов РФ, проанализированных с помощью NGS (секвенирование нового
        поколения),
        были отобраны случаи, удовлетворяющие строгим клиническим критериям наследственных раков по 17 онкосиндромам.
      </ATypographyParagraph>
      <ATypographyParagraph>
        Целью проекта является расширение знаний о генетических основах рака молочной железы, рака яичников, рака
        предстательной железы и рака поджелудочной железы с помощью объединения информации о вариантах в генах HRR и
        соответствующих клинических данных со всей территории России, а также с помощью интерпретации мутаций,
        встречающихся при анализе российских популяций Вся содержащаяся в базе данных OncoLung информация предназначена
        исключительно для использования специалистами онкологами и врачами-генетиками.
      </ATypographyParagraph>
    </ATypography>
  </div>
</template>
<style>
.title {
  height: 38px;

  font-style: normal;

  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */
  text-align: center;
}

.ant-typography {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>
<script setup>
</script>