<template>
  <div style="background-color: white; margin: 10px 10px 10px 10px; padding: 14px; border-radius: 5px;">

    <h1 style="font-size: 30px">Образцы</h1>
    <ATable
        :columns="columns"
        :row-key="record => record.id"
        :dataSource="dataSource"
        :loading="loading"
        :pagination="pagination"
        :custom-row="customRow"
        @change="handleTableChange"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'laboratoryName'">
          <RouterLink :to="'/contacts#' + record.laboratoryId">{{ record.laboratoryName }}</RouterLink>
        </template>
      </template>
    </ATable>
  </div>
</template>
<script setup>
import {usePagination} from 'vue-request';
import {api} from "@/http-common";
import {ref, watch} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();

const variantId = route.params.id;

const columns = [
  {
    title: 'Образец',
    dataIndex: 'patientId',
    key: 'patientId',
  }, {
    title: 'Диагноз',
    dataIndex: 'diagnosis',
    key: 'diagnosis',
  }, {
    title: 'Лаборатория',
    dataIndex: 'laboratoryName',

    key: 'laboratoryName',
  }, {
    title: 'Комментарий',
    dataIndex: 'notes',
    key: 'notes',
  }
];

const queryData = (params) => {
  let url = "/samples?page=" + params.current + '&pageSize=' + params.pageSize;
  if (variantId) {
    url += '&variantId=' + variantId;
  }
  return api.get(url).then((response) => {
    pagination.value.total = response.data.total;
    pagination.value.current = response.data.current;
    pagination.value.pageSize = response.data.pageSize;
    return response.data.data;
  });
}

const {
  data: dataSource,
  run,
  loading,
  current,
  pageSize,
  refresh,
} = usePagination(queryData, {
  pagination: {
    currentKey: 'current',
    pageSizeKey: 'pageSize',
    totalKey: 'total',
    totalPageKey: 'totalPage',
  },
});

const pagination = ref({
  total: 0,
  current: current.value,
  pageSize: pageSize.value,
});

const handleTableChange = (
    page,
    filters,
    sorter,
) => {
  run({
    pageSize: page.pageSize,
    current: page?.current,
    sortField: sorter.field,
    sortOrder: sorter.order,
    ...filters,
  });
};

watch(
    () => route.query,
    async () => {
      current.value = 1;
      refresh();
    }
);
</script>