<template>
  <div class="copyright">
  2024 © OncoLung
  </div>
</template>
<style>
.copyright {
  float: right;
}
</style>
<script setup>
</script>