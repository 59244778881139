export const effects = {
    '3_prime_UTR_variant': "3'-UTR",
    '5_prime_UTR_variant': "5'-UTR",
    'downstream_gene_variant': "Downstream Gene",
    'frameshift_variant': "Frame Shift",
    'inframe_deletion': "Inframe Deletion",
    'inframe_insertion': "Inframe Insertion",
    'intron_variant': "Intron",
    'missense_variant': "Missense",
    'protein_altering_variant': "Protein Altering",
    'splice_acceptor_variant': "Splice Acceptor",
    'splice_donor_variant': "Splice Donor",
    'start_lost': "Start Lost",
    'stop_gained': "Stop Gained",
    'synonymous_variant': "Synonymous",
    'upstream_gene_variant': "Upstream Gene",
    'various': 'Varies',
}

export const clinvarAnnotations = {
    'benign': 'Benign',
    'likely_benign': 'Likely Benign',
    'pathogenic': 'Pathogenic',
    'uncertain_significance': 'Uncertain',
    'likely_pathogenic': 'Likely Pathogenic',
    'not_provided': 'Not Provided',
    'conflicting': 'Conflicting',
    'drug_response': 'Drug Response',
}