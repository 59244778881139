<template>
<div :title="tooltip" style="white-space: nowrap">
{{ label }}
  <CopyOutlined v-if="trimmed" @click="clicked"/>
</div>

</template>
<script setup>
import {toRefs} from "vue";
import { CopyOutlined } from '@ant-design/icons-vue';

const props = defineProps(['reference', 'alt'])

const {reference, alt} = toRefs(props);

const maxSymbols = 1;

let trimmed = false;
let label = "";
if (reference.value.length <= maxSymbols) {
  label += reference.value;
} else {
  trimmed = true;
  label += reference.value.substr(0, maxSymbols) + '…';
}

label += '>';

if (alt.value.length <= maxSymbols) {
  label += alt.value;
} else {
  trimmed = true;
  label += alt.value.substr(0, maxSymbols) + '…';
}

const tooltip = trimmed ? reference.value + ">" + alt.value : '';

const clicked = async () => navigator.clipboard.writeText(reference.value + ">" + alt.value)

</script>