<template>
  <div style="background-color: white; margin: 10px 10px 10px 10px; padding: 14px; border-radius: 5px;">

    <h1 style="font-size: 30px">Образцы</h1>

    <a id="8e564b66-91b7-4c6d-951d-fd3daf5dc272" />
    <ACard title="Лаборатория фармакогеномики ИХБФМ СО РАН">
      <div>Контактное лицо: Филипенко Максим Леонидович</div>
      <div>Электронная почта: <a href="mailto:mlfilipenko@gmail.com">mlfilipenko@gmail.com</a></div>
    </ACard>

    <a id="d1135413-df5f-452e-acd7-f5427704a590" />
    <ACard title="Московский научно-практический центр лабораторных исследований Департамента здравоохранения города Москвы (МНПЦЛИ ДЗМ)">
      <div>Контактное лицо: Гордиев Марат Гордиевич</div>
      <div>Электронная почта: <a href="mailto:marat7925@gmail.com">marat7925@gmail.com</a></div>
    </ACard>

    <a id="752e5f41-310e-4a0b-b962-32b13a7401ff" />
    <ACard title="Лаборатория молекулярно-генетической диагностики ФГБУ &quot;НМИЦ онкологии им. Н.Н. Блохина&quot; Минздрава РФ">
      <div>Контактное лицо: Гордиев Марат Гордиевич</div>
      <div>Электронная почта: <a href="mailto:marat7925@gmail.com">marat7925@gmail.com</a></div>
    </ACard>
  </div>
</template>
<script setup>
</script>